import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html'
})

export class NotFound implements OnInit {
    ngOnInit(): void {

    }
}