<div class="main">
    <div class="title">ANDY TRAN</div>
    <div class="subheader">
        <div class="subtitle">
            LIMS Software Developer
        </div>
        <div class="button-row">
            <a href="https://github.com/Philivox">
                <img src="../../assets/img/github.png" alt="github">
            </a>
            <button mat-mini-fab href="andy@andytran.dev">
                <mat-icon>email</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>

    <div class="left-container">
        <h1 style="color: #ff79c6;">SKILLS</h1><h1>()</h1>
        <div class="brace">&#123;</div>
        <div style="padding-left: 10px;">
            <p>s &#8658; s.<span style="color: #bd93f9;">Angular2+/Javascript</span></p>
            <p>s &#8658; s.<span style="color: #bd93f9;">C#/.NET</span></p>
            <p>s &#8658; s.<span style="color: #bd93f9;">SQL</span></p>
            <p>s &#8658; s.<span style="color: #bd93f9;">Army leadership training</span></p>
        </div>
        <div class="brace">&#125;</div>

        <h1 style="color: #ff79c6;">EDUCATION</h1><h1>()</h1>
        <div class="brace">&#123;</div>
        <div style="padding-left: 10px;">
            <p>e &#8658; e.<span style="color: #bd93f9;">University of Houston</span> 2013-2017</p>
            <p>Studied biotechnology with a focus in bioinformatics graduating with a 3.5 GPA.</p>
        </div>
        <div class="brace">&#125;</div>
    </div>

    <div class="right-container">
        <h1 style="color: #ff79c6;">EXPERIENCE</h1><h1>()</h1>
        <div class="brace">&#123;</div>
        <div style="padding-left: 10px;">
            <p>ex &#8658; ex.<span style="color: #bd93f9;">Gene by Gene</span>
                2018-Present</p>
            <p>Working as a LIMS (Lab IT Management Software) Developer. Full stack development of APIs, front-end interfaces, and designing databases.</p>

            <p>ex &#8658; ex.<span style="color: #bd93f9;">U.S. Army</span>
                2007-2013</p>
            <p>Surgical Technician assisting in over 1,000 hours of surgery. Specialized in Orthopedics and managed supply purchases for the service. </p>
        </div>
        <div class="brace">&#125;</div>
    </div>
</div>